import React, { FormEvent, useState } from "react";
import styled from "styled-components";
import { message } from "antd";
import Button, { ButtonVariantEnum } from "../buttons/Button";
import InputField from "./InputField";
import InfoModals from "./InfoModals";
import axios from "axios";

const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 30px;
  width: 100%;
`;

const Message = styled.div`
  width: 100%;
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.02);
  border: 1px dashed #d9d9d9;
  padding: 10px 20px;
  margin-bottom: 20px;
  color: rgba(0, 0, 0, 0.65);
  font-size: 16px;
`;

const Hightligth = styled.span`
  color: rgb(22, 119, 255);
  font-size: 20px;
`;

interface RegisterFormProps {
  registerConfirmed: boolean
  setRegisterConfirmed: (value: boolean) => void
  toggleLogin: () => void
}

export const RegisterForm: React.FC<RegisterFormProps> = ({setRegisterConfirmed, registerConfirmed, toggleLogin}: RegisterFormProps) => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [infoLinkedinModalVisible, setInfoLinkedinModalVisible] = useState(false);
  const [infoOpenaiModalVisible, setInfoModalOpenaiVisible] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();

  const setEmailFunc = (e: string) => {
    setEmail(e);
  };

  const validateFields = () => {
    if (!firstName || !lastName || !email || !password || !confirmPassword) {
      messageApi.open({
        type: "error",
        content: "Please fill in all required fields.",
      });
      return false;
    }
    if (password !== confirmPassword) {
      messageApi.open({
        type: "error",
        content: "Passwords do not match.",
      });
      return false;
    }
    return true;
  };


  const handleRegister = async (e: FormEvent) => {
    e.preventDefault();
    if (!validateFields()) return;

    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/auth/register`, {
        firstname: firstName,
        lastname: lastName,
        email,
        password,
      });
      if (response.status === 201) {
        setRegisterConfirmed(true)
      }

    } catch (error: any) {
      messageApi.open({
        type: "error",
        content: error.response?.data?.detail || "Error during registration. Please try again.",
      });
      console.error("Registration error:", error.response?.data);
      if (error.response?.status === 400) {
      }
    }
  };
  
  const resetForm = () => {
    setEmail("")
    setPassword("")
    setConfirmPassword("")
    setRegisterConfirmed(false)
    toggleLogin()
  }

  if (registerConfirmed) {
    return (
      <div>
        <Message>
          🎉 Successfully registered! Please confirm your <Hightligth>email</Hightligth> 🎉
        </Message>
        <Button onClick={resetForm} variant={ButtonVariantEnum.PRIMARY}>Return to login page</Button>
      </div>
    )
  }

  return (
    <>
      {contextHolder}
      <Form onSubmit={handleRegister}>
        <InputField
          type="text"
          placeholder="First Name"
          value={firstName}
          onChange={(e) => setFirstName(e.target.value)}
          required
        />
        <InputField
          type="text"
          placeholder="Last Name"
          value={lastName}
          onChange={(e) => setLastName(e.target.value)}
          required
        />
        <InputField
          type="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmailFunc(e.target.value)}
          required
        />
        <InputField
          type="password"
          placeholder="Mot de passe"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
        />
        <InputField
          type="password"
          placeholder="Confirmer le mot de passe"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
          required
        />
        <Button variant={ButtonVariantEnum.PRIMARY}>Inscription</Button>
      </Form>
      <InfoModals
        infoLinkedinModalVisible={infoLinkedinModalVisible}
        setInfoLinkedinModalVisible={setInfoLinkedinModalVisible}
        infoOpenaiModalVisible={infoOpenaiModalVisible}
        setInfoModalOpenaiVisible={setInfoModalOpenaiVisible}
      />
    </>
  );
};

export default RegisterForm;
