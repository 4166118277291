import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { message } from "antd";
import Button, { ButtonVariantEnum } from "../buttons/Button";
import { useSearchParams, useNavigate } from "react-router-dom";
import axios from 'axios';

const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 30px;
  width: 100%;
`;

const Input = styled.input`
  padding: 16px 16px 15px 16px;
  border: none;
  border-radius: 10px;
  width: 100%;
  background: #f5f5f5;
  color: #2c2c2c;
  font-size: 13.333px;
  font-weight: 600;
  line-height: normal;
  &:focus,
  &:active {
    outline: none;
  }
`;

const Message = styled.div`
  width: 100%;
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.02);
  border: 1px dashed #d9d9d9;
  padding: 10px 20px;
  margin-bottom: 20px;
  color: rgba(0, 0, 0, 0.65);
  font-size: 16px;
`;

const NewPassword: React.FC = () => {
  const [newPassword, setNewPassword] = useState("");
  const [newPasswordConfirm, setNewPasswordConfirm] = useState("");
  const [confirmPasswordChanged, setConfirmPasswordChanged] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const token = searchParams.get("token");

  useEffect(() => {
    if (!token) {
      messageApi.open({
        type: "error",
        content: "Invalid or missing token.",
      });
      // navigate("/auth");
    }
  }, [token, messageApi, navigate]);

  const handleSubmitNewPassword = async (e: React.FormEvent) => {
    e.preventDefault();
    if (validatePasswords()) {
      await axios.post(`${process.env.REACT_APP_API_URL}/auth/reset-password`, {
        "token": token,
        "newPassword": newPassword
      }).then(success => {
        setConfirmPasswordChanged(true)
      }).catch(error => {
        messageApi.open({
          type: "error",
          content: "Password has been reset recently, you need to wait 1h before reset it",
        });
      });
    }
  };
  
  const returnToLoginPage = () => {
    navigate('/')
  }
  
  const validatePasswords = () => {
    if (newPassword !== newPasswordConfirm) {
      messageApi.open({
        type: "error",
        content: "Password and confirmation are not identical",
      });
      return false
    }
    return true
  }
  
  if (confirmPasswordChanged) {
    return (
      <div>
        <Message>
          🛠️ Your password change has been confirmed!
        </Message>
        <Button onClick={returnToLoginPage} variant={ButtonVariantEnum.PRIMARY}>Return to login page</Button>
      </div>
    )
  }

  return (
    <>
      {contextHolder}
      <Form onSubmit={handleSubmitNewPassword}>
        <Input
          type="password"
          name="newPassword"
          placeholder="New password"
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
          autoComplete="new-password"
        />
        <Input
          type="password"
          name="newPasswordConfirm"
          placeholder="Confirm password"
          value={newPasswordConfirm}
          onChange={(e) => setNewPasswordConfirm(e.target.value)}
          autoComplete="new-password"
        />
        <Button variant={ButtonVariantEnum.PRIMARY}>Reset Password</Button>
      </Form>
    </>
  );
};

export default NewPassword;
