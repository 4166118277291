import React, { useState, useEffect, ChangeEvent } from 'react';
import { Modal, Input, Select, Tabs, Slider, Typography, List, Button } from 'antd';
import { PlusOutlined, DeleteOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { Persona } from '../../types';

const { Option } = Select;
const { TabPane } = Tabs;
const { Title } = Typography;

interface PersonaModalProps {
  visible: boolean;
  onCreateOrEdit: (persona: Persona) => void;
  persona: Persona | null;
  onCancel: () => void;
}

const FormItem = styled.div`
  margin-bottom: 20px;
`;

const initialFormState: Persona = {
  id: undefined, 
  type: 'individual',
  name: '',
  description: '',
  targetAudience: '',
  toneOfVoice: '',
  language: '',
  postLengthPreference: 'medium',
  emoticonUsage: 5,
  postExamples: [],
};

const PersonaModal: React.FC<PersonaModalProps> = ({ visible, onCreateOrEdit, persona, onCancel }) => {
  const [form, setForm] = useState<Persona>(initialFormState);

  useEffect(() => {
    if (persona) {
      setForm(persona);
    } else {
      setForm(initialFormState);
    }
  }, [persona]);

  const handleInputChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setForm({
      ...form,
      [name]: value,
    });
  };

  const handleSelectChange = (value: any, field: keyof Persona) => {
    setForm({
      ...form,
      [field]: value,
    });
  };

  const handleNumberChange = (value: number | null, field: keyof Persona) => {
    if (value !== null) {
      setForm({
        ...form,
        [field]: value,
      });
    }
  };

  const handleTypeChange = (value: 'individual' | 'company') => {
    setForm({
      ...form,
      type: value,
    });
  };

  const addPostExample = () => {
    setForm({
      ...form,
      postExamples: [...form.postExamples, ''],
    });
  };

  const updatePostExample = (value: string, index: number) => {
    const newPostExamples = [...form.postExamples];
    newPostExamples[index] = value;
    setForm({
      ...form,
      postExamples: newPostExamples,
    });
  };

  const removePostExample = (index: number) => {
    const newPostExamples = [...form.postExamples];
    newPostExamples.splice(index, 1);
    setForm({
      ...form,
      postExamples: newPostExamples,
    });
  };

  const handleSubmit = () => {
    // Validation du formulaire si nécessaire
    onCreateOrEdit(form);
  };

  return (
    <Modal
      title={persona ? 'Edit Persona' : 'Create a new Persona'}
      visible={visible}
      onOk={handleSubmit}
      onCancel={onCancel}
      width={800}
    >
      <Tabs defaultActiveKey="whoami">
        <TabPane tab="Who I Am" key="whoami">
          <FormItem>
            <Title level={5}>Persona Type</Title>
            <Select value={form.type} onChange={handleTypeChange} style={{ width: '100%' }}>
              <Option value="individual">Individual</Option>
              <Option value="company">Company</Option>
            </Select>
          </FormItem>
          <FormItem>
            <Input
              name="name"
              value={form.name}
              onChange={handleInputChange}
              placeholder={form.type === 'individual' ? 'Name' : 'Company Name'}
            />
          </FormItem>
          <FormItem>
            <Input.TextArea
              name="description"
              value={form.description}
              onChange={handleInputChange}
              placeholder="Description"
              rows={4}
            />
          </FormItem>
        </TabPane>
        <TabPane tab="Target Audience" key="target">
          <FormItem>
            <Input.TextArea
              name="targetAudience"
              value={form.targetAudience}
              onChange={handleInputChange}
              placeholder="Description of the target audience"
              rows={4}
            />
          </FormItem>
        </TabPane>
        <TabPane tab="Communication Style" key="communication">
          <FormItem>
            <Title level={5}>Tone of Communication</Title>
            <Select
              value={form.toneOfVoice}
              onChange={(value) => handleSelectChange(value, 'toneOfVoice')}
              placeholder="Select a tone of communication"
              style={{ width: '100%' }}
            >
              <Option value="Professional">Professional</Option>
              <Option value="Casual">Casual</Option>
              <Option value="Humorous">Humorous</Option>
              <Option value="Inspirational">Inspirational</Option>
            </Select>
          </FormItem>
          <FormItem>
            <Input
              name="language"
              value={form.language}
              onChange={handleInputChange}
              placeholder="Language"
            />
          </FormItem>
          <FormItem>
            <Title level={5}>Preference for Post Length</Title>
            <Select
              value={form.postLengthPreference}
              onChange={(value) => handleSelectChange(value, 'postLengthPreference')}
              placeholder="Preference for post length"
              style={{ width: '100%' }}
            >
              <Option value="short">Short</Option>
              <Option value="medium">Medium</Option>
              <Option value="long">Long</Option>
            </Select>
          </FormItem>
          <FormItem>
            <Title level={5}>Emoticon Usage</Title>
            <Slider
              value={form.emoticonUsage}
              min={0}
              max={10}
              onChange={(value) => handleNumberChange(value, 'emoticonUsage')}
              marks={{
                0: '0',
                2: '2',
                4: '4',
                6: '6',
                8: '8',
                10: '10',
              }}
            />
          </FormItem>
        </TabPane>
        <TabPane tab="Post Examples" key="examples">
          <FormItem>
            <Button
              type="dashed"
              onClick={addPostExample}
              style={{ width: '100%', marginBottom: '20px' }}
            >
              <PlusOutlined /> Add a post example
            </Button>
            <List
              dataSource={form.postExamples}
              renderItem={(item, index) => (
                <List.Item
                  actions={[
                    <Button
                      type="text"
                      icon={<DeleteOutlined />}
                      onClick={() => removePostExample(index)}
                    />,
                  ]}
                >
                  <Input.TextArea
                    value={item}
                    onChange={(e) => updatePostExample(e.target.value, index)}
                    placeholder={`Post example ${index + 1}`}
                    autoSize={{ minRows: 2, maxRows: 6 }}
                  />
                </List.Item>
              )}
            />
          </FormItem>
        </TabPane>
      </Tabs>
    </Modal>
  );
};

export default PersonaModal;
