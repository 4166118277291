import React from 'react';
import styled from 'styled-components';
import PersonaList from '../component/Persona/PersonaList ';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  gap: 20px;
`;

export const PersonaPage: React.FC = () => {
  return (
    <Container>
      <PersonaList />
    </Container>
  );
};

export default PersonaPage;
