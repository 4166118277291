import React, { useContext } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import {
    LogoutOutlined
} from '@ant-design/icons';
import { AuthContext } from "../../auth/AuthContext";

const Container = styled.div`
  padding: 12px;
  display: flex;
  flex-direction: row;
  width: 248px;
  background: #fff;
  border-top: 1px solid #e4e2df;
  align-items: center;
  justify-content: center;
  cursor: pointer; /* Ajout du curseur pointeur */
  transition: background-color 0.3s ease; /* Transition pour le changement de fond */
  color: #333333; 

  &:hover {
    background-color: #f0f0f0; /* Couleur de fond lors du survol */
  }
`;

const Text = styled.div`
  font-size: 14px;
  margin-left: 8px; /* Ajout d'un espacement entre l'icône et le texte */
`;



export const Logout = () => {
    const { logout } = useContext(AuthContext);
    const navigate = useNavigate(); // Initialize the useNavigate hook

    const handleLogout = () => {
        logout(); // Call the logout function from context
        navigate('/'); // Redirect to home page after logout
    };


    return (
        <Container onClick={handleLogout}>
            <LogoutOutlined />
            <Text>Logout</Text>
        </Container>
    );
};
