import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Card, List, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { LinkedinPostService } from '../service/LinkedinPostService';
import { AuthContext } from '../auth/AuthContext';

const Container = styled.div`
    padding: 20px;
    overflow-y: auto;

    &:focus {
        outline: none;
    }

    &::-webkit-scrollbar {
        height: 7px;
        width: 7px;
    }

    &::-webkit-scrollbar-track {
        border-radius: 5px;
        background: rgba(200, 200, 200, 0.1);
    }

    &::-webkit-scrollbar-thumb {
        background: #AAA;
        border-radius: 5px;
    }

    &::-webkit-scrollbar-thumb:hover {
        background: #888;
    }

    &::-webkit-scrollbar-button {
        display: none;
    }
`;

const antIcon = <LoadingOutlined style={{ fontSize: 52 }} spin />;

interface Post {
  id: string;
  content: string;
  imageUrl?: string; // L'image est optionnelle
  createdAt: Date;
}

const PostCard = styled(Card)`
    display: flex;
    flex-direction: column;
    height: 350px; // Hauteur fixe pour toutes les cartes
`;

const ContentContainer = styled.div`
    height: 150px; // Hauteur fixe pour la zone de texte
    display: flex;
    flex-direction: column;
    overflow: hidden;
`;

const DateLine = styled.p`
    color: #888;
    font-size: 12px;
    margin: 0;
    flex: 0 0 auto;
`;

const TextContent = styled.p`
    margin: 0;
    flex: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 6; // Nombre de lignes maximum
    -webkit-box-orient: vertical;
`;

const ImageContainer = styled.div`
    width: 100%;
    height: 150px; // Hauteur fixe pour l'image
    background-color: #f0f0f0; // Couleur de fond pour les images manquantes
    display: flex;
    align-items: center;
    justify-content: center;

    img {
        height: 100%;
        width: 100%;
        object-fit: cover;
    }
`;

const PostHistory: React.FC = () => {
    const [posts, setPosts] = useState<Post[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const { myToken } = useContext(AuthContext);
    
    useEffect(() => {
      LinkedinPostService.getUserPosts(myToken)
        .then(responsePosts => {
          const postsPromise = responsePosts?.map(async post => {
            let image = post.images && post.images.length > 0 ? post.images[0].id : undefined;
            if (image) {
              const blob = await LinkedinPostService.getPostImage(myToken, post.id!, image!);
              image = URL.createObjectURL(blob);
            }
            return {
              content: post.content,
              createdAt: post.creationDate,
              imageUrl: image,
              id: post.id,
            } as Post;
          });
          return Promise.all(postsPromise!)
        })
        .then(posts => {
          setPosts(posts!);
          setLoading(false);
          
        });
    }, [myToken]);
    
    return (
      <Container>
        {loading ? (
          <Spin indicator={antIcon} style={{ display: 'block', margin: 'auto' }} />
        ) : (
          <List
            grid={{
              gutter: 16,
              xs: 1,
              sm: 1,
              md: 2,
              lg: 3,
              xl: 3,
              xxl: 4,
            }}
            dataSource={posts}
            pagination={{
              pageSize: 12,
            }}
            renderItem={(post) => (
              <List.Item>
                <PostCard hoverable>
                  <ContentContainer>
                    <DateLine>
                      Publié le {new Date(post.createdAt).toLocaleDateString()}
                    </DateLine>
                    <TextContent>{post.content}</TextContent>
                  </ContentContainer>
                  <ImageContainer>
                    {post.imageUrl ? (
                      <img alt="history post" src={post.imageUrl} />
                    ) : (
                      <div style={{ color: '#aaa' }}>Aucune image</div>
                    )}
                  </ImageContainer>
                </PostCard>
              </List.Item>
            )}
          />
        )}
      </Container>
    );
  }
;

export default React.memo(PostHistory);