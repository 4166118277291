import React, { createContext, useEffect, useState } from 'react';
import axios from 'axios';

export const AuthContext = createContext();

const getToken = () => {
  const token = localStorage.getItem('jwtToken');
  return token ? token : ''; // Retourne une chaîne vide si aucun token n'est trouvé
};

export const AuthProvider = ({ children }) => {
  const [authUser, setAuthUser] = useState(null);
  const [isLoading, setIsLoading] = useState(true); // Nouvel état pour le chargement
  const [myToken, setMyToken] = useState(null); // Nouvel état pour le chargement

  const updateAuthState = async () => {
    setIsLoading(true);
    const token = getToken();
    if (!token) {
      setAuthUser(null);
      setMyToken(null);
      setIsLoading(false);
      return;
    }
    try {
      // Remplacez cette URL par celle de votre API qui valide le token JWT
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/auth/profile/`, {
        headers: {
          Authorization: `Bearer ${token}`,
          accept: 'application/json'
        }
      });

      let user_link = {};
      try {
        const response_linkedin = await fetch(`${process.env.REACT_APP_API_URL}/linkedin/user`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          }
        });

        if (!response_linkedin.ok) {
          throw new Error('Network response was not ok');
        }

        const data_linkedin = await response_linkedin.json();
        user_link = { linkedin_name: data_linkedin.name, profileImage: data_linkedin.picture };
      } catch (error) {
        console.error('Failed to fetch LinkedIn user:', error);
      }

      const mergedUser = { ...response.data, ...user_link }; // Fusion des données utilisateur et LinkedIn
      setAuthUser(mergedUser); // Assurez-vous que le back-end renvoie les bonnes données utilisateur
      setMyToken(token);

      setIsLoading(false);
    } catch (error) {
      console.error('Token validation error:', error);
      localStorage.removeItem('jwtToken');
      setAuthUser(null);
      setIsLoading(false);
      setMyToken(null);
    }
  };

  const logout = () => {
    localStorage.removeItem('jwtToken'); // Remove token from localStorage
    setAuthUser(null); // Reset user data
    setMyToken(null); // Reset token state
    setIsLoading(false); // Ensure loading state is false
  };

  useEffect(() => {
    updateAuthState();
  }, []);

  return (
    <AuthContext.Provider value={{ authUser, isLoading, myToken, updateAuthState, logout }}>
      {children}
    </AuthContext.Provider>
  );
};
