import React, { useContext} from 'react';
import styled from 'styled-components';
import Iconimg from "../../assets/icon.png"
import { CaretDownOutlined, CommentOutlined, LikeOutlined, PictureOutlined, SendOutlined, SmileOutlined, SyncOutlined } from '@ant-design/icons';
import { AuthContext } from '../../auth/AuthContext';

const PreviewFooter = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap:10px;
`;

const Menu = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  padding: 4px 25px;
  gap:10px;
  justify-content: space-evenly;
`;

const FakeButton = styled.div`
  display: flex;
  flex-direction: row;
  height: 48px;
  padding: 10px 8px;
  gap:4px;
  background: transparent;
  align-items: center;
  justify-content: center;
  border-radius: 5px;

  transition: background 0.1s ease;
  cursor:pointer;
  font-weight: 600;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.65);

  &:hover{
      background: #fafafa;
  }
`;

const User = styled.img`
  width: 24px;
  height: 24px;
  border-radius: 50%;
`;

const Stat = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 30px;
  padding: 4px 25px;
  gap:3px;
  font-weight: 600;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.5);
  align-items: center;
`;

const Impression = styled.div`
  flex-grow : 1;
  align-items: center;
  height: 30px;
  display: flex;
  flex-direction: row;
`;

const Analyse = styled.div`
  color: rgb(10, 102, 194)
`;

const Icon = styled.img`
  width: 24px;
  height: 24px;
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 4px 16px;
  gap:10px;
`;

const UserInput = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 50%;
`;

const Input = styled.div`
  flex-grow: 1;
  height: 40px;
  border: 1px solid #e4e2df;
  border-radius: 30px;
  flex-direction: row;
  display: flex;
  gap:10px;
  align-items: center;
  padding: 0 10px;
  color: rgba(0, 0, 0, 0.7);
  font-weight: 400;
  font-size: 14px;
`;
const InputText = styled.div`
  flex-grow: 1;
`;


export const Footer: React.FC = () => {
  const { authUser } = useContext(AuthContext);

  return (
    <PreviewFooter>
      <Menu>
        <FakeButton>
          {authUser && authUser.profileImage ? (
            <User src={authUser.profileImage} alt="user" />
          ) : (
            <User src={"https://images.pexels.com/photos/771742/pexels-photo-771742.jpeg"} alt="default user" />
          )}
          <CaretDownOutlined />
        </FakeButton>
        <FakeButton>
          <LikeOutlined /> Like
        </FakeButton>
        <FakeButton>
          <CommentOutlined /> Comment
        </FakeButton>
        <FakeButton>
          <SyncOutlined /> Repost
        </FakeButton>
        <FakeButton>
          <SendOutlined /> Send
        </FakeButton>
      </Menu>
      <Stat>
        <Impression>
          <Icon src={Iconimg} alt="icon" /> 215 impressions
        </Impression>
        <Analyse>
          View analytics
        </Analyse>
      </Stat>
      <InputContainer>
        {authUser && authUser.profileImage ? (
          <UserInput src={authUser.profileImage} alt="user input" />
        ) : (
          <UserInput src={"https://images.pexels.com/photos/771742/pexels-photo-771742.jpeg"} alt="default user input" />
        )}
        <Input>
          <InputText>Add comment...</InputText><SmileOutlined /><PictureOutlined />
        </Input>
      </InputContainer>
    </PreviewFooter>
  );
};
