import axios from 'axios';
import { UserApiKeyApiRequest, UserApiKeyModelApi } from '../model/UserApiKeyModelApi';

export class UserApiKeyService {
  private static USER_API_KEY_BASE_URL = `${process.env.REACT_APP_API_URL}/users-api-key`;
  
  static async getUserApiKey(token: string): Promise<UserApiKeyModelApi | null> {
    try {
      const response = await axios.get<UserApiKeyModelApi>(`${this.USER_API_KEY_BASE_URL}`, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });
      return response.data;
    } catch (error) {
      console.error('Error while fetching user api keys:', error);
      throw error;
    }
  }
  
  static async saveUserApiKey(token: string, apiKey: string): Promise<UserApiKeyModelApi | null> {
    const request = {
      apiKey: apiKey
    } as UserApiKeyApiRequest
    try {
      const response = await axios.post<UserApiKeyModelApi>(`${this.USER_API_KEY_BASE_URL}`, request, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });
      return response.data;
    } catch (error) {
      console.error('Error while saving user api key:', error);
      throw error;
    }
  }
  
  static async deleteUserApiKey(token: string): Promise<UserApiKeyModelApi | null> {
    try {
      const response = await axios.delete<UserApiKeyModelApi>(`${this.USER_API_KEY_BASE_URL}`, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });
      return response.data;
    } catch (error) {
      console.error('Error while deleting user api key:', error);
      throw error;
    }
  }
}