import React, { useEffect, useState, useContext, useRef, useCallback } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { ArticleType } from '../types';
import { AuthContext } from '../auth/AuthContext';
import { Select } from 'antd';
import type { SelectProps } from 'antd';
import List from '../component/home/List';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  gap: 20px;
  padding: 50px 50px;
`;

const ChannelContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
`;

const ScrollableListContainer = styled.div`
  height: 100%;
  background: #fff;
  border-radius: 25px;
  padding: 20px 30px;
  border: 1px solid #D2D6DB;
`;

export const ArticlesPage: React.FC = () => {
  const [articles, setArticles] = useState<ArticleType[]>([]);
  const { myToken } = useContext(AuthContext);
  const listContainerRef = useRef<HTMLDivElement>(null);
  const [keywords, setKeywords] = useState<SelectProps['options']>([]);
  const [containerHeight, setContainerHeight] = useState(0);

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_URL}/api/keywords`, {
      headers: {
        'accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${myToken}`
      },
    })
      .then(response => {
        const fetchedKeywords = response.data.map((keyword: string) => ({
          label: keyword,
          value: keyword,
        }));
        setKeywords(fetchedKeywords);
      })
      .catch(error => console.error('Error fetching keywords:', error));
  }, [myToken]);

  useEffect(() => {
    const updateHeight = () => {
      if (listContainerRef.current) {
        const measurements = listContainerRef.current.getBoundingClientRect();
        setContainerHeight(measurements.height - 40);
      }
    };

    updateHeight(); // Update height initially
    window.addEventListener('resize', updateHeight); // Update height on resize

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('resize', updateHeight);
    };
  }, []);

  const fetchArticlesByKeywords = useCallback((selectedKeywords: string[]) => {
    const keywordsParam = selectedKeywords.map(keyword => `keywords=${encodeURIComponent(keyword)}`).join('&');
    axios.get(`${process.env.REACT_APP_API_URL}/api/articles/by_keywords?${keywordsParam}`, {
      headers: {
        'accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${myToken}`
      },
    })
      .then(response => {
        const fetchedArticles = response.data.map((article: any) => ({
          keyword: article.keyword,
          title: article.title,
          author: article.author || 'Unknown',
          url: article.url,
          description: article.description,
          imageUrl: article.url_to_image,
          publishedAt: article.published_at,
          content: article.content,
        }));
        setArticles(fetchedArticles);
      })
      .catch(error => console.error('Error fetching articles:', error));
  }, [myToken]);

  const handleChange = useCallback((value: string[]) => {
    fetchArticlesByKeywords(value);
  }, [fetchArticlesByKeywords]);

  return (
    <Container>
      <ChannelContainer>
        <Select
          mode="multiple"
          allowClear
          style={{ width: '100%' }}
          placeholder="Please select"
          defaultValue={[]}
          onChange={handleChange}
          options={keywords}
        />
      </ChannelContainer>
      <ScrollableListContainer ref={listContainerRef}>
        <List articles={articles} containerHeight={containerHeight} />
      </ScrollableListContainer>
    </Container>
  );
};
