function generateCommonStyles(item:any) {
    let style = `position: absolute; `;
    style += `left: ${item.x || 0}px; `;
    style += `top: ${item.y || 0}px; `;
    style += `opacity: ${item.opacity !== undefined ? item.opacity : 1}; `;
    if (item.rotation) {
        style += `transform: rotate(${item.rotation}deg); `;
    }
    if (item.visible === false) {
        style += `display: none; `;
    }
    return style;
}

function generateElementHTML(item:any) {
    let style = generateCommonStyles(item);
    let elementHtml = '';

    switch (item.type) {
        case 'rect':
            style += `width: ${(item.width || 100) * (item.scaleX || 1)}px; `;
            style += `height: ${(item.height || 100) * (item.scaleY || 1)}px; `;
            style += `background-color: ${item.fill || 'transparent'}; `;
            style += `border: ${item.strokeWidth || 0}px solid ${item.stroke || 'transparent'}; `;
            if (item.cornerRadius) {
                style += `border-radius: ${item.cornerRadius}px; `;
            }
            if (item.shadowBlur) {
                style += `box-shadow: 0px 0px ${item.shadowBlur}px #000000; `;
            }
            elementHtml = `<div id="${item.id}" style="${style}"></div>`;
            break;

        case 'circle':
            let diameter = (item.radius || 50) * 2;
            // Ajuster left et top pour centrer le cercle
            let left = (item.x || 0) - (item.radius || 50);
            let top = (item.y || 0) - (item.radius || 50);
            style = `position: absolute; `;
            style += `left: ${left}px; `;
            style += `top: ${top}px; `;
            style += `opacity: ${item.opacity !== undefined ? item.opacity : 1}; `;
            if (item.rotation) {
                style += `transform: rotate(${item.rotation}deg); `;
            }
            if (item.visible === false) {
                style += `display: none; `;
            }
            style += `width: ${diameter}px; `;
            style += `height: ${diameter}px; `;
            style += `background-color: ${item.fill || 'transparent'}; `;
            style += `border-radius: 50%; `;
            style += `border: ${item.strokeWidth || 0}px solid ${item.stroke || 'transparent'}; `;
            if (item.shadowBlur) {
                style += `box-shadow: 0px 0px ${item.shadowBlur}px #000000; `;
            }
            elementHtml = `<div id="${item.id}" style="${style}"></div>`;
            break;

        case 'image':
            let width = (item.width || 100) * (item.scaleX || 1);
            let height = (item.height || 100) * (item.scaleY || 1);
            style += `width: ${width}px; `;
            style += `height: ${height}px; `;
            style += `object-fit: contain; `;
            elementHtml = `<img id="${item.id}" src="${item.imageSrc}" style="${style}"/>`;
            break;

        case 'text':
            style += `font-size: ${item.fontSize || 16}px; `;
            style += `font-family: ${item.fontFamily || 'sans-serif'}; `;
            style += `color: ${item.fill || '#000000'}; `;
            style += `width: ${item.width || 'auto'}px; `;
            if (item.height) {
                style += `height: ${item.height}px; `;
            }
            elementHtml = `<span id="${item.id}" style="${style}">${item.text}</span>`;
            break;

        default:
            console.warn(`Type non supporté : ${item.type}`);
            break;
    }

    return elementHtml;
}

export function convertKonvaObjectsToHTML(konvaObjects: any) {
    let pagesHtml : string[] = []; // Liste pour stocker le HTML de chaque page

    konvaObjects.forEach((objectList:any) => {
        let html = ''; // Chaîne pour accumuler le HTML de la page actuelle

        objectList.forEach((item:any) => {
            const elementHtml = generateElementHTML(item);
            if (elementHtml) {
                html += elementHtml + '\n';
            }
        });

        pagesHtml.push(html); // Ajouter le HTML de la page à la liste des pages
    });

    return pagesHtml; // Retourner la liste des chaînes HTML
}