import styled from 'styled-components';
import logoImage from '../assets/logo.png';
import React, { useEffect, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import Button, { ButtonVariantEnum } from '../component/buttons/Button';
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import axios from 'axios';

const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #E6F4F8;
`;

const Card = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 18px 28px;
    background-color: #fff;
    border-radius: 3px;
    box-shadow: 0px 5px 5px 2px rgba(0, 0, 0, 0.02);
`;

const ContainerLeft = styled.div`
    display: flex;
    min-width: 348.5px;
    padding: 12px;
    flex-direction: column;
    gap: 50px;
    flex: 1 0 0;
`;

const LogoStyled = styled.div`
    height: 64px;
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 10px;
`;

const Logo = styled.div`
    width: 42px;
    height: 42px;
    background: url(${logoImage}) no-repeat center center;
    background-size: contain;
`;

const Title = styled.div`
    font-size: 24px;
    color: #636D79;
    font-weight: 700;
    letter-spacing: 2px;
`;

const ErrorMessage = styled.div`
    color: #e74c3c;
    font-size: 18px;
    font-weight: bold;
    padding: 20px;
    background-color: #ffebee;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const SuccessIcon = styled(CheckCircleOutlined)`
  font-size: 60px;
  color: #2ecc71;
  margin-bottom: 20px;
`;

const ErrorIcon = styled(CloseCircleOutlined)`
  font-size: 60px;
  color: #e74c3c;
  margin-right: 10px;
`;

const CenterIcon = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
`;

const EmailConfirmed: React.FC = () => {
  const hasExecutedRef = useRef(false);
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');
  const [error, setError] = useState(false);
  useEffect(() => {
    if (hasExecutedRef.current) {
      return;
    }
    hasExecutedRef.current = true;
    if (token) {
      axios.get(`${process.env.REACT_APP_API_URL}/auth/register-email-confirm`, {
        params: {
          token: token
        }}).then(response => {
          const bearerToken = response.data.bearerToken
          localStorage.setItem('jwtToken', bearerToken);
        }).catch(error => {
          setError(true)
      })
    } else {
      setError(true);
    }
  }, [token]);
  
  const redirectHome = () => {
    window.location.replace('/')
  };
  
  return (
    <Container>
      <Card>
        <LogoStyled>
          <Logo />
          <Title>PostPilot</Title>
        </LogoStyled>
        <ContainerLeft>
          {!error &&
            <div>
              <CenterIcon>
                <SuccessIcon />
              </CenterIcon>
              <Button variant={ButtonVariantEnum.PRIMARY} onClick={redirectHome}>Redirect to PostPilot app</Button>
            </div>
          }
          {error &&
            <ErrorMessage>
              <ErrorIcon />
              An error has occurred
            </ErrorMessage>
          }
        </ContainerLeft>
      </Card>
    </Container>
  );
};

export default EmailConfirmed;