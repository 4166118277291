import React, { useState } from "react";
import styled from "styled-components";
import Button, { ButtonVariantEnum } from "../buttons/Button";
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 30px;
  width: 100%;
`;

const Input = styled.input`
  padding: 16px 16px 15px 16px;
  border: none;
  border-radius: 10px;
  width: 100%;
  background: #f5f5f5;
  color: #2c2c2c;
  font-size: 13.333px;
  font-weight: 600;
  line-height: normal;
  &:focus,
  &:active {
    outline: none;
  }
`;

const Message = styled.div`
  width: 100%;
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.02);
  border: 1px dashed #d9d9d9;
  padding: 10px 20px;
  margin-bottom: 20px;
  color: rgba(0, 0, 0, 0.65);
  font-size: 16px;
`;

const ForgotPassword: React.FC = () => {
  const navigate = useNavigate()
  const [email, setEmail] = useState("");
  const [resetPasswordSuccess, setResetPasswordSuccess] = useState(false)

  const handleSendEmail = async (e: React.FormEvent) => {
    e.preventDefault();
    await axios.post(`${process.env.REACT_APP_API_URL}/auth/reset-password-request`, {
      "email": email
    }).then(success => {
      setResetPasswordSuccess(true)
    });
  }
  
  const returnToLoginPage = () => {
    navigate('/')
  }
  
  if (resetPasswordSuccess) {
    return (
      <div>
        <Message>
          🛠️ Your password has been successfully reset!
        </Message>
        <Button onClick={returnToLoginPage} variant={ButtonVariantEnum.PRIMARY}>Return to login page</Button>
      </div>
    )
  }

  return (
    <>
      <Form onSubmit={handleSendEmail}>
        <Input
          type="email"
          name="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          autoComplete="email"
        />
        <Button variant={ButtonVariantEnum.PRIMARY}>Send Password Reset Email</Button>
      </Form>
    </>
  );
};

export default ForgotPassword;
