import React, { useContext, useEffect } from 'react';
import styled from 'styled-components';
import type { ProgressProps } from 'antd';
import { Progress } from 'antd';
import backgroundImage from '../../assets/bg_credit.png';
import { AuthContext } from '../../auth/AuthContext';

// interface CreditStatusProps {
//     credits: number;
//     onUpgrade: () => void;
// }

const twoColors: ProgressProps['strokeColor'] = {
  '0%': '#108ee9',
  '100%': '#87d068',
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 30px;
  background-image: url(${backgroundImage});
  background-size: cover;
  background-position: center;
  border-radius: 8px;
`;

const CreditsText = styled.h2`
  color: #fff;
  font-size: 14px;
`;

const UpgradeButton = styled.div`
  background-color: #fff;
  color: #028CB5;
  border: none;
  margin-top: 20px;
  cursor: pointer;
  padding: 8px 0;
  width: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;

  &:hover {
    background-color: #F2F2F2;
  }
`;

const CreditContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 5px;
    border-radius: 8px;
    background: rgba(255, 255, 255, 0.1);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    width: 100%;
    align-items: center;
    justify-content: center;
    padding: 20px 5px;
`;


export const CreditStatus: React.FC = () => {
  const { authUser } = useContext(AuthContext);
  const [creditTotal, setCreditTotal] = React.useState<number>(0);
  const [percent, setPercent] = React.useState<number>(0);

  useEffect(() => {
    if (authUser) {
      if (authUser.subscription_type === "free") {
        setCreditTotal(1);
        setPercent((authUser?.credits / 1) * 100);
      }
      else if (authUser.subscription_type === "basic") {
        setCreditTotal(8);
        setPercent((authUser?.credits / 8) * 100);
      }
      else if (authUser.subscription_type === "pro") {
        setCreditTotal(23);
        setPercent((authUser?.credits / 23) * 100);
      }
      else if (authUser.subscription_type === "admin") {
        setCreditTotal(999999999);
        setPercent((authUser?.credits / 999999999) * 100);
      }
    }
  }, [authUser]);

  return (
    <>
      {authUser?.credits ?
        <Container>
          <CreditContainer>
            <CreditsText>{authUser.subscription_type}</CreditsText>
            <Progress type="dashboard" percent={100 - percent} strokeColor={twoColors} size={80} />
            <CreditsText>{authUser?.credits} / {creditTotal}</CreditsText>
            <CreditsText>Crédit restant</CreditsText>
          </CreditContainer>
          <UpgradeButton onClick={() => { }}>
            Get Pro
          </UpgradeButton>
        </Container>
        : null}
    </>
  );
};
