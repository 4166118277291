import { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { AuthContext } from './AuthContext';

export const PrivateRoute = ({ component: Component }) => {
  const { authUser, isLoading } = useContext(AuthContext);

  if (isLoading) {
    return <div>Chargement...</div>; // ou un spinner
  }

  return authUser ? <Component /> : <Navigate to="/auth" />;
};

