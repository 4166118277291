import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';
import { Persona } from '../../types';
import axios from 'axios';
import { AuthContext } from '../../auth/AuthContext';

const PageContainer = styled.div`
  width: 100%;
  display: flex;
  gap: 16px;
  flex-direction: column;
  max-height: 200px;
  overflow-y: auto;
`;

const PersonaCard = styled.div`
  border: 1px solid rgb(240, 240, 240);
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: #fff;
  font-size: 12px;
  padding: 10px;
  color: #22222;
  transition: background-color 0.3s ease, color 0.3s ease, border 0.3s ease;

  &:hover {
    background-color: #f8f8f8;
    color: rgb(2, 140, 181);
    border: 1px solid rgb(2, 140, 181);
  }
`;

const ContentContainer = styled.div`
  flex: 1;
`;

const ContentParagraph = styled.p`
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* Adjust the number of lines as needed */
  -webkit-box-orient: vertical;
`;

interface PersonaProps{
    onSelectPersonaFunc:any;
}


const PersonaStyle: React.FC<PersonaProps> = ({onSelectPersonaFunc}) => {
  const [personas, setPersonas] = useState<Persona[]>([]);
  const { myToken } = useContext(AuthContext);

  useEffect(() => {
    const fetchPersonas = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/personas/user`, {
          headers: {
            Authorization: `Bearer ${myToken}`,
          },
        });
        setPersonas(response.data);
      } catch (error) {
        console.error('Error fetching personas:', error);
      }
    };

    fetchPersonas();
  }, [myToken]);


  return (
    <PageContainer>
        {personas.map((persona:Persona) => (
          <PersonaCard key={persona.id} onClick={() => {onSelectPersonaFunc(persona)}}>
            <ContentContainer>
              <ContentParagraph>
                {persona.name}
              </ContentParagraph>
            </ContentContainer>
          </PersonaCard>
        ))}
    </PageContainer>
  );
};

export default PersonaStyle;
