import React, { useContext, useEffect } from 'react';
import { AuthForm } from '../component/AuthForm';
import { AuthContext } from '../auth/AuthContext';
import { useNavigate } from 'react-router-dom';

const LoginPage: React.FC = () => {
  const { authUser } = useContext(AuthContext);
  const navigate = useNavigate()
  
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get('token');
    if (token) {
      localStorage.setItem('jwtToken', token);
      navigate('/');
    }
  }, [navigate]);
  
  if (authUser) {
    window.location.replace('/')
  }
  return <AuthForm />;
};

export default LoginPage;
