import React, { useContext, useEffect, useState } from 'react';
import { UploadOutlined } from '@ant-design/icons';
// import {OneToOneOutlined} from '@ant-design/icons';
import { Button as AntButton, Carousel, message, Modal, Spin, Upload } from 'antd';
import styled from 'styled-components';
import { AuthContext } from '../../auth/AuthContext';
import { PostTemplateModelApi } from '../TemplateEditor/models/PostTemplateModelApi';
import { convertKonvaObjectsToHTML } from '../../utils/template';

const { Dragger } = Upload;

interface UploadOrTemplateProps {
    image: string | null;
    setImage: (image: string | null) => void;
    template: any | null;
    setTemplate: (template: any | null) => void;
}

interface Template {
    id: string;
    name: string;
}

const UploadOrTemplate: React.FC<UploadOrTemplateProps> = ({ image, setImage, template, setTemplate }) => {
    const [selectedTemplate, setSelectedTemplate] = useState<string[]>([]);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [loadingTemplates, setLoadingTemplates] = useState(false);
    const [templates, setTemplates] = useState<Template[]>([]); // Pour stocker les templates
    const { myToken } = useContext(AuthContext);

    // Fonction pour récupérer les templates de l'utilisateur
    const fetchTemplates = async (myToken:any) => {
        setLoadingTemplates(true);
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/post-templates/user`, {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${myToken}`,
                },
            });

            if (!response.ok) {
                throw new Error('Failed to fetch templates');
            }

            const data = await response.json();
            setTemplates(data);
        } catch (error) {
            message.error('Failed to fetch templates');
        } finally {
            setLoadingTemplates(false);
        }
    };

    useEffect(() => {
        fetchTemplates(myToken);
    }, [myToken]);

    useEffect(() => {
    }, [templates]);

    // Ouvrir le modal et récupérer les templates si ce n'est pas déjà fait
    // const showModal = () => {
    //     setIsModalVisible(true);
    // };

    // Fermer le modal
    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const handleTemplateSelect = (template: Template) => {
        setTemplate(template);
        setIsModalVisible(false);
        fetch(`${process.env.REACT_APP_API_URL}/post-templates/${template.id}`, {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${myToken}`,
            },
        })
            .then((response) => response.json())
            .then((templateData: PostTemplateModelApi) => {
                const jsonData = templateData.jsonData ? JSON.parse(templateData.jsonData) : [[]];
                const htmlTemplate = convertKonvaObjectsToHTML(jsonData);
                setSelectedTemplate(htmlTemplate);
            })
            .catch((error) => console.error('Error fetching template content:', error));
    };

    const handleImageUpload = (info: any) => {
        const file = info.fileList[0].originFileObj;
        const isImage = file.type.startsWith('image/');
        if (!isImage) {
            message.error('You can only upload image files!');
            return;
        }

        const reader = new FileReader();
        reader.onload = (e) => {
            setImage(e.target?.result as string);
            message.success(`${info.file.name} file uploaded successfully`);
        };

        if (file) {
            reader.readAsDataURL(file);
        }
    };

    const handleImageRemove = () => {
        setImage(null);
    };

    // const handleTemplateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    //     const newTemplate = e.target.value;
    //     setSelectedTemplate(newTemplate);
    //     setTemplate(newTemplate);
    // };

    return (
        <Container>
            {(image || selectedTemplate.length > 0) ?
                image ? (
                    <Result>
                        <img src={image} alt="uploaded" style={{ width: '100%' }} />
                        <AntButton onClick={handleImageRemove}>Remove Image</AntButton>
                    </Result>
                ) : (
                    <Result>
                        {selectedTemplate.length > 0 ? (
                            <StyledCarousel draggable={true} arrows infinite={false}>
                                {selectedTemplate.map((htmlContent, index) => (
                                    <Slide key={index}>
                                        <div
                                            style={{
                                                position: 'relative',
                                                width: '100%',
                                                height: '100%',
                                            }}
                                            dangerouslySetInnerHTML={{ __html: htmlContent }}
                                        />
                                    </Slide>
                                ))}
                            </StyledCarousel>
                        ) : (
                            <Spin />
                        )}
                        <AntButton onClick={() => {
                            setSelectedTemplate([]);
                        }}>
                            Remove Template
                        </AntButton>
                    </Result>
                )
                : (
                    <>
                        <DraggerContainer>
                            <Dragger
                                name="image"
                                beforeUpload={() => false}
                                onChange={handleImageUpload}
                                showUploadList={false}
                            >
                                <p className="ant-upload-drag-icon">
                                    <UploadOutlined />
                                </p>
                                <p className="ant-upload-text">Click or drag image to upload</p>
                                <p className="ant-upload-hint">Only image files are allowed.</p>
                            </Dragger>
                        </DraggerContainer>

                        {/* <TemplateContainer onClick={showModal}>
                            <TemplateContainerIcon>
                                <OneToOneOutlinedIcon />
                            </TemplateContainerIcon>
                            <TemplateContainerTitle>Select a Template</TemplateContainerTitle>
                            <TemplateContainerSubTitle>Choose from your saved templates</TemplateContainerSubTitle>
                        </TemplateContainer> */}
                    </>
                )
            }

            <Modal
                title="Select a Template"
                visible={isModalVisible}
                onCancel={handleCancel}
                footer={null}
            >
                {loadingTemplates ? (
                    <Spin />
                ) : (
                    <TemplateList>
                        {templates.map((template) => (
                            <TemplateItem
                                key={template.id}
                                onClick={() => handleTemplateSelect(template)}
                            >
                                {template.name}
                            </TemplateItem>
                        ))}
                    </TemplateList>
                )}
            </Modal>

        </Container >
    );
};

const TemplateList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const TemplateItem = styled.div`
  padding: 10px;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
  max-height: 400px;
  overflow-y: auto;

  &:hover {
    background-color: #f5f5f5;
  }
`;

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  padding: 0 20px;
`;

const Result = styled.div`
  width: 100%;
  display: flex;
  gap: 5px;
  flex-direction: column;
  align-items: center;

`;

const DraggerContainer = styled.div`
  flex: 1;
`;

// const TemplateContainer = styled.div`
//     flex: 1;
//     text-align: center;
//     background: rgba(0, 0, 0, 0.02);
//     border: 1px dashed #d9d9d9;
//     border-radius: 8px;
//     cursor: pointer;
//     transition: border-color 0.3s;
//     display: flex;
//     flex-direction: column;
//     align-items: center;
//     justify-content: center;

//     &:hover {
//         border-color: #028CB5;
//     }
// `;

// const TemplateContainerIcon = styled.p`
//     font-size: 14px;
//     margin-bottom: 16px;
//     text-align: center;
//     font-weight: 600;
// `;

// const OneToOneOutlinedIcon = styled(OneToOneOutlined)`
//     color: rgb(22, 119, 255);
//     font-size: 48px;
// `;

// const TemplateContainerTitle = styled.p`
//     font-size: 16px;
//     font-weight: 600;
//     margin: 0 0 4px;
//     color: rgba(0, 0, 0, 0.88);
// `;

// const TemplateContainerSubTitle = styled.p`
//     font-size: 14px;
//     font-weight: 600;
//     color: rgba(0, 0, 0, 0.45);
//     text-align: center;
// `;

const Slide = styled.div`
  position: relative;
  width: 270px;
  height: 338px;
  overflow: hidden;
  border-radius: 15px;
  border: 1px solid #C2C2C2;
  background-color: #fff; 
`;

const StyledCarousel = styled(Carousel)`
  width: 270px;
  .slick-slide {
    display: flex;
    justify-content: center;
    align-items: center;
  }

    .slick-arrow.slick-next,
    .slick-arrow.slick-prev {
        color: #000;
    }
`;


export default UploadOrTemplate;
