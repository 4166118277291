import React, { FC, useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import Button, { ButtonVariantEnum } from '../buttons/Button';
import { Input, message, Modal } from 'antd';
import { sendEmailClicked } from '../AuthForm/tools';
import { SubscriptionService } from '../../service/subscription.service';
import { SubscriptionModelApi } from '../../model/SubscriptionModelApi';
import { AuthContext } from '../../auth/AuthContext';
import { UserApiKeyService } from '../../service/UserApiKeyService';
import { UserApiKeyModelApi } from '../../model/UserApiKeyModelApi';
import LinkedinButton from '../buttons/LinkedinButton';

/*
 * Styles.
 */

const Container = styled.div`
    display: flex;
    align-items: center;
    gap: 25px;
    flex-direction: column;
    width: 100%;
    overflow-y: auto;
`;

const Cell = styled.div`
    display: flex;
    align-items: flex-start;
    gap: 15px;
    align-self: stretch;
`;

const TitleCell = styled.span`
    color: #727272;
    font-size: 16px;
    font-weight: 400;
    width: 40%;
`;

const Divider = styled.div`
    display: flex;
    padding-top: 15px;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    align-self: stretch;
`;

const Line = styled.div`
    height: 1px;
    align-self: stretch;
    background: #D9D9D9;
`;

const Columns = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
`;

const InputContainer = styled.div`
    display: flex;
    width: 500px;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
`;

const InputTitle = styled.div`
    color: #363636;
    font-size: 12.8px;
    font-weight: 400;
`;

const Display = styled.div`
    display: flex;
    padding: 16px 21px 16px 20px;
    align-items: center;
    gap: 10px;
    align-self: stretch;
    border-radius: 15px;
    border: none;
    background: #F7F7F7;
    font-size: 0.8rem;
    font-weight: 400;
`;

const LinkedinContainer = styled.div`
`;

const LinkedinTitle = styled.div`
    color: #363636;
    font-size: 12.8px;
    font-weight: 400;
    margin-bottom: 20px;
`;

const LinkedinLoginContainer = styled.div`
`;


interface ProfileProps {
  authUser: any;
}

export const Profile: FC<ProfileProps> = ({ authUser }) => {
  const [messageApi, contextHolder] = message.useMessage();
  const { myToken, updateAuthState } = useContext(AuthContext);
  const [subscription, setSubscription] = useState<SubscriptionModelApi>();
  const [linkedinApiKey, setLinkedinApiKey] = useState<UserApiKeyModelApi | null>();
  const [linkedinApiKeyInput, setLinkedinApiKeyInput] = useState<string>();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [linkedinApiKeyError, setLinkedinApiKeyError] = useState<string>();


  const handlePasswordUpdate = async () => {
    sendEmailClicked(authUser.email, messageApi);
  };

  useEffect(() => {
    SubscriptionService.getUserSubscription(myToken).then(result => {
      if (result) {
        setSubscription(result);
      }
    });
    UserApiKeyService.getUserApiKey(myToken).then(apiKey => {
      setLinkedinApiKey(apiKey);
    });
  }, [myToken]);

  const handleCancel = (e: any) => {
    setLinkedinApiKeyError('');
    setLinkedinApiKeyError('');
    setIsModalOpen(false);
  };

  const handleOk = (e: any) => {
    e.preventDefault();
    if (!linkedinApiKeyInput || linkedinApiKeyInput === '') {
      setLinkedinApiKeyError('Linkedin API KEY should not be null');
      return;
    }
    UserApiKeyService.saveUserApiKey(myToken, linkedinApiKeyInput!).then(apiKey => {
      setLinkedinApiKey(apiKey);
      updateAuthState();
    });
    setLinkedinApiKeyInput('');
    setIsModalOpen(false);
  };

  // const deleteLinkedinApiKey = () => {
  //   UserApiKeyService.deleteUserApiKey(myToken).then(_ => {
  //     setLinkedinApiKey(null);
  //     setLinkedinApiKeyError('');
  //     setIsModalOpen(false);
  //     setLinkedinApiKeyInput('');
  //     updateAuthState();
  //   });
  // };

  const apiKeyInputChange = (e: any) => {
    e.preventDefault();
    setLinkedinApiKeyInput(e.target.value);
  };

  const linkedInLogin = () => {
    window.open(`${process.env.REACT_APP_API_URL}/auth/linkedin`, '_self');
  }

  console.log('AUTH USER');
  console.log(JSON.stringify(authUser));

  return (
    <Container>
      {contextHolder}
      {authUser ? (
        <>
          <Modal title="Add Linkedin api key" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
            <Input placeholder="Add your linkedin api key"
              onChange={(e) => apiKeyInputChange(e)} />
            {linkedinApiKeyError && <p style={{ color: 'red' }}>{linkedinApiKeyError}</p>}
          </Modal>
          <Cell>
            <TitleCell>Identity</TitleCell>
            <Columns>
              <InputContainer>
                <InputTitle>Full Name</InputTitle>
                <Display>
                  {authUser.linkedin_name}
                </Display>
              </InputContainer>
              <InputContainer>
                <InputTitle>Email</InputTitle>
                <Display>
                  {authUser.email}
                </Display>
              </InputContainer>
            </Columns>
          </Cell>
          {!authUser.linkedinSso &&
            <>
              <Divider><Line /></Divider>
              <Cell>
                <TitleCell>Linkedin</TitleCell>
                <Columns>
                  <LinkedinContainer>
                    <LinkedinTitle>Synchronize your account with Linkedin</LinkedinTitle>
                    <LinkedinLoginContainer>
                      <LinkedinButton text={"Sign in with LinkedIn"} onClick={linkedInLogin}/>
                    </LinkedinLoginContainer>
                  </LinkedinContainer>
                  <InputContainer>

                    {
                      // <InputTitle>Api Key</InputTitle>
                      // linkedinApiKey
                      //  ? <Display>
                      //    <DisplayLinkedinApiKey>
                      //      <span>**********</span>
                      //      <Popconfirm
                      //        title="Delete Linkedin api key"
                      //        description="Are you sure you want to delete user api key ?"
                      //        onConfirm={deleteLinkedinApiKey}
                      //      >
                      //        <CloseOutlined />
                      //      </Popconfirm>
                      //    </DisplayLinkedinApiKey>
                      //  </Display>
                      //  : <Button variant={ButtonVariantEnum.PRIMARY} onClick={() => setIsModalOpen(true)}>
                      //    Add new api key
                      //  </Button>
                    }
                  </InputContainer>
                </Columns>
              </Cell>
              <Divider><Line /></Divider>
              <Cell>
                <TitleCell>Password</TitleCell>
                <Columns>
                  <InputContainer>
                    <Button variant={ButtonVariantEnum.PRIMARY} onClick={handlePasswordUpdate}>
                      Send Password Reset Email
                    </Button>
                  </InputContainer>
                </Columns>
              </Cell>
            </>
          }
          <Divider><Line /></Divider>
          <Cell>
            <TitleCell>Subscription Details</TitleCell>
            <Columns>
              <InputContainer>
                <InputTitle>Subscription Type</InputTitle>
                <Display>
                  {subscription
                    ? subscription?.variantName
                    : 'You are not subscribed'}
                </Display>
              </InputContainer>
            </Columns>
          </Cell>

        </>
      ) : null}
    </Container>
  );
};
