import React  from "react";
import styled from "styled-components";
import { useNavigate } from 'react-router-dom';
import { ArrowLeftOutlined } from '@ant-design/icons';
import logoImage from '../assets/logo.png';
import ForgotPassword from "../component/AuthForm/ForgotPassword";

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #E6F4F8;
`;

const Card = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 18px 28px;
  background-color: #fff;
  border-radius: 3px;
  box-shadow: 0px 5px 5px 2px rgba(0, 0, 0, 0.02);
`;

const ContainerLeft = styled.div`
  display: flex;
  min-width: 348.5px;
  padding: 12px;
  flex-direction: column;
  gap: 50px;
  flex: 1 0 0;
`;

const BackButton = styled.div`
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  padding: 5px;
  border-radius: 50%; /* Makes the button round */
  background-color: transparent;
  transition: background-color 0.3s;

  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
`;

const ArrowLeftOutlinedStyled = styled(ArrowLeftOutlined)`
  font-size: 16px;
  color: #333333;
`;

const LogoStyled = styled.div`
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin-bottom: 10px;
  position: relative;
  width: 100%;
`;

const Logo = styled.div`
  width: 42px;
  height: 42px;
  background: url(${logoImage}) no-repeat center center;
  background-size: contain;
`;

const Title = styled.div`
  font-size: 24px;
  color: #636D79;
  font-weight: 700;
  letter-spacing: 2px;
`;

const ForgotPasswordPage: React.FC = () => {
  const navigate = useNavigate();

  const back = () => {
    navigate('/auth');
  };

  return (
    <Container>
      <Card>
        <LogoStyled>
          <BackButton onClick={back}>
            <ArrowLeftOutlinedStyled />
          </BackButton>
          <Logo />
          <Title>PostPilot</Title>
        </LogoStyled>
        <ContainerLeft>
          <ForgotPassword />
        </ContainerLeft>
      </Card>
    </Container>
  );
};

export default ForgotPasswordPage;
