import React from "react";
import styled from "styled-components";
import logoImage from '../assets/logo.png';
import NewPassword from "../component/AuthForm/NewPassword";

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #E6F4F8;
`;

const Card = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 18px 28px;
  background-color: #fff;
  border-radius: 3px;
  box-shadow: 0px 5px 5px 2px rgba(0, 0, 0, 0.02);
`;

const ContainerLeft = styled.div`
  display: flex;
  min-width: 348.5px;
  padding: 12px;
  flex-direction: column;
  gap: 50px;
  flex: 1 0 0;
`;

const LogoStyled = styled.div`
  height: 64px;
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;
`;

const Logo = styled.div`
  width: 42px;
  height: 42px;
  background: url(${logoImage}) no-repeat center center;
  background-size: contain;
`;

const Title = styled.div`
  font-size: 24px;
  color: #636D79;
  font-weight: 700;
  letter-spacing: 2px;
`;

const ResetPasswordPage: React.FC = () => {

  return (
    <Container>
      <Card>
        <LogoStyled>
          <Logo />
          <Title>PostPilot</Title>
        </LogoStyled>
        <ContainerLeft>
          <NewPassword />
        </ContainerLeft>
      </Card>
    </Container>
  );
};

export default ResetPasswordPage;
