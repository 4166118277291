import React, { FormEvent, useContext, useState } from 'react';
import styled from 'styled-components';
import Button, { ButtonVariantEnum } from '../buttons/Button';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import { AuthContext } from '../../auth/AuthContext';
import { message } from 'antd';
import LinkedinButton from '../buttons/LinkedinButton';

const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 20px;
  width: 100%;
`;

const InputPassword = styled.div`
  width: 100%;
  gap: 5px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
`;

const Input = styled.input`
  padding: 16px 16px 15px 16px;
  border: none;
  border-radius: 10px;
  width: 100%;
  background: #f5f5f5;
  color: #2c2c2c;
  font-size: 13.333px;
  font-weight: 600;
  line-height: normal;
  &:focus,
  &:active {
    outline: none;
  }
`;

const ForgotPasswordLink = styled(Link)`
  color: #1890ff;
  font-size: 12px;
  &:hover {
    text-decoration: underline;
  }
`;

const OrContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
`;

const OrSlash = styled.div`
  height: 1px;
  background: #dadce0;
  flex: 1;
`;

const OrText = styled.span`
  color: #dadce0;
  font-size: 16px;
  font-weight: 600;
  line-height: normal;
`;

const ContainerLinkedin = styled.div`
  width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
`;

export const LoginForm: React.FC = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [messageApi, contextHolder] = message.useMessage();
  const navigate = useNavigate();

  const { updateAuthState } = useContext(AuthContext);

  const handleLogin = async (e: FormEvent) => {
    e.preventDefault();
    const formData = new URLSearchParams();
    formData.append("username", email);
    formData.append("password", password);

    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/auth/login`, formData, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded"
        }
      });

      const { bearerToken } = response.data;
      localStorage.setItem("jwtToken", bearerToken);
      await updateAuthState();
      navigate("/");
    } catch (error: any) {
      messageApi.open({
        type: "error",
        content: "Email ou mot de passe incorrect."
      });
      console.error("Login error:", error.response?.data);
    }
  };

  const linkedInLogin = () => {
    console.log("LINKEDIN SSO TRIGGER")
    window.open(`${process.env.REACT_APP_API_URL}/auth/linkedin`, '_self');
  }


  return (
    <>
      {contextHolder}
      <Form onSubmit={handleLogin}>
        <ContainerLinkedin>
          <LinkedinButton text={"Sign in with LinkedIn"} onClick={linkedInLogin}/>
        </ContainerLinkedin>
        <OrContainer>
          <OrSlash/>
          <OrText>or</OrText>
          <OrSlash />
        </OrContainer>
        <Input
          type="email"
          name="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          autoComplete="email"
        />
        <InputPassword>
          <Input
            type="password"
            name="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            autoComplete="current-password"
          />
          <ForgotPasswordLink to="/auth/forgot-password">Forgot Password?</ForgotPasswordLink>
        </InputPassword>
        <Button variant={ButtonVariantEnum.PRIMARY}>Connect</Button>
      </Form>
    </>
  );
};
