import axios from 'axios';
import { LinkedinPostApiResponse } from '../model/LinkedinUserPostsApi';

export class LinkedinPostService {
  private static LINKEDIN_POST_BASE_URL = `${process.env.REACT_APP_API_URL}/linkedin`;
  
  static async getUserPosts(token: string, pageNumber: number = 0, pageSize: number = 20): Promise<LinkedinPostApiResponse[] | null> {
    try {
      const response = await axios.get<LinkedinPostApiResponse[]>(`${this.LINKEDIN_POST_BASE_URL}/posts`, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });
      return response.data;
    } catch (error) {
      console.error('Error while fetching user posts:', error);
      throw error;
    }
  }
  
  static async getPostImage(token: string, postId: string, postImageId: string) {
    try {
      const response = await axios.get(`${this.LINKEDIN_POST_BASE_URL}/posts/${postId}/images/${postImageId}`, {
        responseType: 'blob',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });
      return response.data;
    } catch (error) {
      console.error('Error while fetching image posts:', error);
      throw error;
    }
  }
}