import axios from 'axios';
import { ProductModelApi, SubscriptionModelApi } from '../model/SubscriptionModelApi';

export class SubscriptionService {
  private static SUBSCRIPTION_BASE_URL = `${process.env.REACT_APP_API_URL}/subscription`;
  
  static async getUserSubscription(token: string): Promise<SubscriptionModelApi | null> {
    try {
      const response = await axios.get<SubscriptionModelApi>(`${this.SUBSCRIPTION_BASE_URL}`, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching user subscription:', error);
      throw error;
    }
  }
  
  static async getCheckoutUrl(token: string, variantId: string): Promise<string> {
    try {
      const response = await axios.get<string>(`${this.SUBSCRIPTION_BASE_URL}/checkout`, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        params: {
          variantId,
        },
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching checkout URL:', error);
      throw error;
    }
  }
  
  static async getProductsSubscription(token: string): Promise<ProductModelApi[]> {
    try {
      const response = await axios.get<ProductModelApi[]>(`${this.SUBSCRIPTION_BASE_URL}/products`, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        }});
      return response.data;
    } catch (error) {
      console.error('Error while fetching products and variants :', error);
      throw new Error();
    }
  }
}