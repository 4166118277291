import React, { useState, useContext } from "react";
import styled from 'styled-components';
import { AuthContext } from "../auth/AuthContext";
import { Tabs } from "../component/Settings/Tabs";
import { Secrets } from "../component/Settings/Secrets";
import { Profile } from "../component/Settings/Profile";


const Container = styled.div`
    display: flex;
    height: 100%;
    width: 100%;
    align-items: center;
    gap: 25px;
    flex-direction: column;
    padding: 50px 50px;
`;

const Line = styled.div`
    height: 1px;
    align-self: stretch;
    background: #D9D9D9;
`;


export const Settings: React.FC = () => {
  const { authUser, myToken, checkUser } = useContext(AuthContext);
  const [selectTab, setSelectTab] = useState<string>("Profile");

  const renderTabContent = () => {
    switch(selectTab) {
      case 'Profile':
        return <Profile authUser={authUser}/>;
      case 'Secrets':
        return <Secrets authUser={authUser} myToken={myToken} checkUser={checkUser}/>;
      default:
        return null;
    }
  }

  return (
    <Container>
      <Tabs selectTab={selectTab} setSelectTab={setSelectTab} tabList={["Profile", "Secrets"]}/>
      <Line/>
      {renderTabContent()}
    </Container>
  );
};
