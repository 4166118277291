import React from 'react';
import styled from 'styled-components';
import { Video } from '../../types';
import { CloseOutlined } from '@ant-design/icons';

// Styled components for the list and list items

const ListItemContainer = styled.div`
  display: flex;
  padding: 10px;
  border-radius: 8px;
  background-color: #fff;
  align-items: center;

  cursor: pointer;

  transition: background 0.1s ease;
  border: 1px solid #e4e2df;

  &:hover{
      background: #fafafa;
  }
`;

const ItemImage = styled.img`
  height: 55px;
  object-fit: cover;
  border-radius: 4px;
  margin-right: 10px;
  font-size: 24px;
`;

const ItemDetails = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-grow: 1;
`;

const CloseComponent = styled(CloseOutlined)`
  transition: color 0.3s;
  cursor: pointer;
  &:hover {
    color: #E64D4D;
  }
`;

const ItemTitle = styled.h4`
  margin: 0;
  color: #333;
  font-size: 16px;
`;

const ItemDescription = styled.p`
  margin: 0;
  color: #666;
  font-size: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
`;

// Props type definitions
type ListItemProps = {
  video: Video;
  onClose: () => void;
};



// ListItem component
export const YoutubeCard: React.FC<ListItemProps> = ({ video, onClose }) => {

  //function tempsEcoule(dateStr: string): string {
  //  const date = new Date(dateStr);
  //  const maintenant = new Date();
  //  // Utiliser .getTime() pour obtenir des millisecondes et permettre l'opération arithmétique
  //  const diff = maintenant.getTime() - date.getTime(); // Différence en millisecondes
//
  //  const secondes = Math.floor(diff / 1000);
  //  const minutes = Math.floor(secondes / 60);
  //  const heures = Math.floor(minutes / 60);
  //  const jours = Math.floor(heures / 24);
  //  const mois = Math.floor(jours / 30);
  //  const annees = Math.floor(mois / 12);
//
  //  // Déclaration des types pour les paramètres de la fonction
  //  function pluralize(unit: number, word: string): string {
  //    return unit === 1 ? `il y a 1 ${word}` : `il y a ${unit} ${word}s`;
  //  }
//
  //  if (annees > 0) {
  //    return pluralize(annees, 'an');
  //  } else if (mois > 0) {
  //    return pluralize(mois, 'mois');
  //  } else if (jours > 0) {
  //    return pluralize(jours, 'jour');
  //  } else if (heures > 0) {
  //    return pluralize(heures, 'heure');
  //  } else if (minutes > 0) {
  //    return pluralize(minutes, 'minute');
  //  } else {
  //    return pluralize(secondes, 'seconde');
  //  }
  //}

  return (
    <ListItemContainer>
      <ItemImage src={video.thumbnailUrl} alt="Article" />
      <ItemDetails>
        <ItemTitle>{video.title}</ItemTitle>
        <ItemDescription>{video.channelTitle} ⸱  {video.publishedDate}</ItemDescription>
      </ItemDetails>
      <CloseComponent onClick={onClose} />
    </ListItemContainer>
  )
}
