import styled from 'styled-components';
import Button, { ButtonVariantEnum } from '../component/buttons/Button';
import { SubscriptionService } from '../service/subscription.service';
import React, { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../auth/AuthContext';
import { ProductModelApi } from '../model/SubscriptionModelApi';
import { useNavigate } from 'react-router-dom';

const Container = styled.div`

`;

const SubscriptionHeader = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
`;

const SubscriptionContent = styled.div`
    margin: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const ContentDescription = styled.div`
    width: 85%;
`;

const Title = styled.h1`
    font-size: 4rem;
    width: 75%;
    margin: 5rem 0;
`;

const Description = styled.p`
    font-size: 2rem;
    width: 60%;
    margin-bottom: 2rem;
`;

export const SubscriptionPage: React.FC = () => {
  
  const { authUser, myToken } = useContext(AuthContext);
  const [products, setProducts] = useState<ProductModelApi[]>([])
  const navigate = useNavigate();
  
  if (authUser.subscriptionPlan !== 'NO_PLAN') {
    navigate('/')
  }
  
  useEffect(() => {
    SubscriptionService.getProductsSubscription(myToken).then(response => {
      setProducts(response)
    })
  }, [myToken]);
  
  const generateCheckoutUrl = (variantId: string) => {
    SubscriptionService.getCheckoutUrl(myToken, variantId).then(url => {
      window.location.href = url
    })
  }
  
  
  return (
    <Container>
      <SubscriptionHeader>
        <Title>Make your app
          the best it can be with Firebase and generative AI</Title>
        <Description>
          Build & run modern, AI-powered experiences users love with Firebase, a platform designed to support you throughout your app development journey. Backed by Google and trusted by millions of businesses around the world.
        </Description>
        {
          products.map(product =>
            <div>
              <Button variant={ButtonVariantEnum.PRIMARY} onClick={() => generateCheckoutUrl(product.variants[0].id)}>{product.name}</Button>
            </div>
          )
          
        }
      </SubscriptionHeader>
      <SubscriptionContent>
        <ContentDescription>
        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Accusantium alias aperiam cupiditate earum eos eveniet harum illum, impedit maiores minima molestiae nobis, possimus ratione, sunt vitae. Asperiores at autem cupiditate delectus earum mollitia nam soluta veniam veritatis! Accusamus cum dicta distinctio doloremque, ea facere fuga harum repellendus saepe voluptate. A alias architecto assumenda at aut consequuntur cum cumque dolor dolorem dolorum earum eligendi, error eum exercitationem harum iusto libero maiores modi nemo nihil nisi obcaecati omnis optio qui, quibusdam quidem repellendus rerum sunt vero, voluptates. Aspernatur, at commodi cumque id labore, minus pariatur quas, suscipit unde velit veritatis vitae voluptatum!
        </ContentDescription>
      </SubscriptionContent>
    </Container>
  );
};




