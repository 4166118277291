import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { AuthContext } from '../auth/AuthContext';
import {
  ArticleContainer,
  Container,
  LeftContainer,
  RightContainer,
  RightFooter,
} from '../component/CreatePost/Styles';
import { Preview } from '../component/CreatePost/PreviewContainer';
import Idea from '../component/CreatePost/Idea';
import { ArticleType, FormValues, Video } from '../types';
import type { TourProps } from 'antd';
import { message, Spin, Tour } from 'antd';
import { InfoCircleOutlined, LoadingOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import LinkedinButton from '../component/buttons/LinkedinButton';
import aiLogo from '../assets/ai_logo.png';

const LoadingOverlay = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 200px;
  height: 200px;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 16px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(10px);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
`;

const DescriptionContainer = styled.div`
  max-width: 100%;
  word-wrap: break-word;
  line-height: 1.6;
  color: #333;
  font-size: 1rem;
`;

// List styling to keep it contained within the DescriptionContainer
const StyledList = styled.ul`
  padding-left: 20px; // Adjust left padding for proper indent within the card
  margin: 10px 0;
  list-style-type: disc;

  li {
    margin-bottom: 0.5rem;
  }
`;

const InfoButton = styled.div`
  padding: 0 16px;
  cursor: pointer;
  background-color: #fff;
  border: 1px solid #e4e2df;
  border-radius: 10px;
  font-size: 13.333px;
  font-weight: 600;
  line-height: normal;
  color: #e4e2df;

  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  height: 46px;

  transition: border 0.3s ease, color 0.3s ease;

  &:hover {
    border: 1px solid #028CB5;
    color: #028CB5;
  }
`;

const GenerateButton = styled.div`
  padding: 0 16px;
  cursor: pointer;
  background-color: #028CB5;
  border-radius: 10px;
  font-size: 13.333px;
  font-weight: 600;
  line-height: normal;
  color: #fff;

  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  height: 46px;

  transition: background-color 0.3s ease;

  &:hover {
    background-color: rgba(2, 140, 181, 0.75);
  }
`;

const StepOneDescription = () => (
  <DescriptionContainer>
    Welcome aboard! This app is here to help you generate top-notch LinkedIn posts with style, sourced from multiple
    creative avenues. This quick tour will guide you through each feature, so you’ll be crafting the perfect post in no
    time! Ready? Let’s dive in!
  </DescriptionContainer>
);

const StepTwoDescription = () => (
  <DescriptionContainer>
    On the left, you’ll see a preview panel showing exactly how your post will look on LinkedIn. Play around, experiment,
    and watch your ideas come to life! Now, let’s move on to where the magic really happens…
  </DescriptionContainer>
);

const StepThreeDescription = () => (
  <DescriptionContainer>
    Here’s where things get interesting! We offer four sources to generate content with unique twists:
    <StyledList>
      <li>
        <strong>Idea</strong>: Have a quick thought or prompt? Turn it into a post with a simple entry! Just type your
        idea, and let our tool do the rest! ✍️
      </li>
      <li>
        <strong>Articles</strong>: Stay in the loop with trending news! Choose from various recent articles to give
        your post a fresh, current vibe! 📰
      </li>
      <li>
        <strong>Video</strong>: Grab a YouTube link and use it to create an engaging post. It’s perfect for a more
        interactive touch! 🎥
      </li>
      <li>
        <strong>URL</strong>: Got a blog or website link? Pop it in here, and watch as we transform it into
        post-worthy content! 🔗
      </li>
    </StyledList>
  </DescriptionContainer>
);

const StepFourDescription = () => (
  <DescriptionContainer>
    Now it’s time to add your personal touch! You have two options for styling your posts:
    <StyledList>
      <li>
        <strong>Manual</strong>: Choose your tone and style for each post.
      </li>
      <li>
        <strong>Persona</strong>: Want a consistent voice? Create a Persona to define your unique expression style.
        Add detailed examples and a description, and we’ll adapt each post to match your vibe effortlessly!
      </li>
    </StyledList>
    And voilà, you’re all set! Enjoy exploring the sources, experimenting with style, and crafting posts that truly stand
    out.
  </DescriptionContainer>
);

const antIcon = <LoadingOutlined style={{ fontSize: 52 }} spin />;

const CreatePost: React.FC = () => {
  const location = useLocation();
  const { myToken } = useContext(AuthContext);
  const [contentPost, setContentPost] = useState<string>("");
  const [image, setImage] = useState<string | null>(null);
  const [template, setTemplate] = useState<any | null>(null);
  const [loading, setLoading] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const [open, setOpen] = useState<boolean>(false);
  const [file, setFile] = useState<File | null>(null);
  
  const ref1 = useRef(null);
  const ref2 = useRef(null);
  const ref3 = useRef(null);
  const ref4 = useRef(null);
  const ref5 = useRef(null);

  const steps: TourProps['steps'] = [
    {
      title: 'Welcome to Your Posting Playground! 🎉',
      description: <StepOneDescription />,
      target: null,
    },
    {
      title: 'See Your Post in Action! 👀',
      description: <StepTwoDescription />,
      placement: 'right',
      target: () => ref1.current,
    },
    {
      title: 'Choose Your Source of Inspiration! 💡',
      description: <StepThreeDescription />,
      placement: 'left',
      target: () => ref2.current,
    },
    {
      title: 'Pick Your Style 🎨',
      description: <StepFourDescription />,
      placement: 'left',
      target: () => ref3.current,
    },
    {
      title: 'Generate Your Post! 🚀',
      description: 'Click here to generate your LinkedIn post content.',
      placement: 'top',
      target: () => ref4.current,
    },
    {
      title: 'Post to LinkedIn! 🔗',
      description: 'Ready to publish? Click here to post directly to LinkedIn!',
      placement: 'top',
      target: () => ref5.current,
    },
  ];

  useEffect(() => {
    // Vérifie si l'utilisateur a déjà vu le tour et si l'appareil est de taille supérieure à un mobile
    const hasSeenTour = localStorage.getItem('hasSeenTour');
    const isDesktop = window.innerWidth >= 768; // Détecte si l'écran est plus grand qu'un mobile
  
    if (!hasSeenTour && isDesktop) {
      setOpen(true); // Ouvre le tour si non vu et si l'écran est assez grand
      localStorage.setItem('hasSeenTour', 'true'); // Marque le tour comme vu
    }
  }, []);
  

  const initialStateRef = useRef(location.state);
  const [formValues, setFormValues] = useState<FormValues>({
    source: 'idea',
    topic: '',
    points: '',
    article: '',
    transcription: '',
    urlContent: '',
    style: 'mystyle',
    tone: '',
    pronoun: '',
    length: '',
    language: '',
    linkedinProfile: '',
    personaId: null,
  });

  const [selectedVideo, setSelectedVideo] = useState<Video | null>(null);
  const [selectedArticle, setSelectedArticle] = useState<ArticleType | null>(null);

  useEffect(()=> {
  }, [formValues])

  useEffect(() => {
    const initialState = initialStateRef.current;

    if (initialState) {
      setFormValues(prevValues => ({
        ...prevValues,
        source: initialState?.type || 'idea',
        article: initialState?.type === 'article' ? initialState.data.content : '',
        transcription: initialState?.type === 'video' ? initialState.data.description : '',
      }));

      if (initialState?.type === 'video') {
        setSelectedVideo(initialState.data);
      } else if (initialState?.type === 'article') {
        setSelectedArticle(initialState.data);
      }
    }
  }, []);

  const handleGenerateClick = useCallback(async () => {
    if (formValues) {
      setLoading(true);
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/generation`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${myToken}`
          },
          body: JSON.stringify(formValues)
        });

        if (!response.ok) {
          const errorData = await response.json();
          if (response.status == 403) {
            throw new Error(errorData.message);
          }
          throw new Error(errorData.detail || 'Erreur lors de la génération.');
        }

        const data = await response.json();
        setContentPost(data.generatedPost);
      } catch (error: any) {
        messageApi.open({
          type: "error",
          content: error.message || "Erreur lors de la génération.",
        });
      } finally {
        setLoading(false);
      }
    }
  }, [formValues, myToken, messageApi]);

  const handleFormChange = useCallback((changedValues: Partial<FormValues>, allValues: FormValues) => {
    setFormValues(allValues);
  }, []);

  const isValidUrl = useCallback((string: string) => {
    try {
      new URL(string);
      return true;
    } catch (e) {
      return false;
    }
  }, []);

  const handlePost = useCallback(async () => {
    // event.preventDefault();
    setLoading(true);

    if (contentPost) {
      let body = JSON.stringify({ content: contentPost });
      
      const formData = new FormData();
      formData.append('content', contentPost);
      
      if (image) {
        formData.append('imageUrl', '');
      }
      if (file) {
        formData.append('file', file);
      }

      console.log("POST")
      console.log(formData)
      
      try {
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/linkedin/posts`, formData, {headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${myToken}`,
          }})

        const result = await response.data;

        if (response.status) {
          if (result.message && isValidUrl(result.message)) {
            window.open(result.message, '_blank');
          }
        } else {
          throw new Error(result.message || 'Échec de la publication');
        }
      } catch (error: any) {
        messageApi.open({
          type: "error",
          content: error.message || 'Erreur lors de la connexion au serveur',
        });
      } finally {
        setLoading(false);
      }
    } else {
      setLoading(false);
    }
  }, [contentPost, image, isValidUrl, myToken, messageApi]);

  return (
    <Container>
      {contextHolder}
      <Tour open={open} onClose={() => setOpen(false)} steps={steps} />
      {loading && (
        <LoadingOverlay>
          <Spin indicator={antIcon} />
        </LoadingOverlay>
      )}
      <LeftContainer >
        <Preview
          loading={loading}
          contentPost={contentPost}
          setContentPost={setContentPost}
          image={image}
          setImage={setImage}
          template={template}
          setTemplate={setTemplate}
          ref={ref1}
        />
      </LeftContainer>
      <RightContainer>
        <ArticleContainer>
          <Idea
            onGenerate={handleGenerateClick}
            onFormChange={handleFormChange}
            formValues={formValues}
            loading={loading}
            setLoading={setLoading}
            setImage={setImage}
            initialSelectedVideo={selectedVideo}
            initialSelectedArticle={selectedArticle}
            refIdea={ref2}
            refStyle={ref3}
          />
        </ArticleContainer>
        <RightFooter>
          <GenerateButton ref={ref4} onClick={handleGenerateClick}>
            <img src={aiLogo} alt="logo_ai" width={23} />
            Generate Post
          </GenerateButton>
          <LinkedinButton ref={ref5} text={"Publish on LinkedIn"} onClick={() => handlePost()}/>
          <InfoButton onClick={() => setOpen(true)}><InfoCircleOutlined /></InfoButton>
        </RightFooter>
      </RightContainer>
    </Container>
  );
};

export default React.memo(CreatePost);
