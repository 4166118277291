import styled from 'styled-components';

const Container = styled.div`
  padding: 50px 50px;
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100vh;
  gap: 30px;
  

  @media (max-width: 768px) {
    flex-wrap: wrap;
    flex-direction: column-reverse;
    height: auto;
    padding: 10px 10px;
  }
`;

const LeftContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  width: 567px;
  height: 100%;

  @media (max-width: 768px) {
    width: 100%;
    height: auto;
    overflow-y: visible;
  }
`;

const RightContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: 10px;
  position: relative;
  height: 100%;

  @media (max-width: 768px) {
    width: 100%;
    height: auto;
  }
`;

const ArticleContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  background: #fff;
  border-radius: 8px;
  flex-grow: 1;
  border: 1px solid #e4e2df;
  overflow-y: auto;

  &::-webkit-scrollbar {
    height: 7px;
    width: 7px;
  }

  &::-webkit-scrollbar-track {
    border-radius: 5px;
    background: rgba(200, 200, 200, 0.1);
  }

  &::-webkit-scrollbar-thumb {
    background: #AAA;
    border-radius: 5px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #888;
  }

  &::-webkit-scrollbar-button {
    display: none;
  }

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const RightFooter = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 10px;
  justify-content: flex-end;

  @media (max-width: 768px) {
    justify-content: center;
  }
`;

export {
  Container,
  LeftContainer,
  RightContainer,
  ArticleContainer,
  RightFooter
};
