import React from 'react';
import { Routes, Route } from "react-router-dom";
import { Settings } from './pages/Settings';
import { PrivateRoute } from './auth/PrivateRoute';
import { CreateCarousel } from './pages/CreateCarousel';
import YoutubePage from './pages/Youtube';
import CreatePost  from './pages/CreatePost';
import { ArticlesPage } from './pages/Articles';

import LoginPage from './pages/LoginPage';
import ForgotPasswordPage from './pages/ForgotPassword';
import ResetPasswordPage from './pages/ResetPassword';
// import TemplatePage from './pages/Template';
import { SubscriptionPage } from './pages/Subscription';
import { PersonaPage } from './pages/Persona';
import EmailConfirmed from './pages/EmailConfirmed';
import PostHistory from './pages/PostHistory';


export const RouterComponent: React.FC = () => {
  return (
    <Routes>
      <Route path="/auth/forgot-password" element={<ForgotPasswordPage />} />
      <Route path="/auth/reset-password" element={<ResetPasswordPage />} />
      <Route path="/auth/register-email-confirm" element={<EmailConfirmed />} />
      <Route path="/auth" element={<LoginPage />} />
      <Route path="/" element={<PrivateRoute component={CreatePost} />} />
      <Route path="/carousel" element={<PrivateRoute component={CreateCarousel} />} />
      <Route path="/post-history" element={<PrivateRoute component={PostHistory} />} />
      <Route path="/sources/videos" element={<PrivateRoute component={YoutubePage} />} />
      <Route path="/sources/articles" element={<PrivateRoute component={ArticlesPage} />} />
      <Route path="/settings" element={<PrivateRoute component={Settings} />} />
      {/* <Route path="/template" element={<PrivateRoute component={TemplatePage} />} /> */}
      <Route path="/subscribe" element={<PrivateRoute component={SubscriptionPage} />} />
      <Route path="/persona" element={<PrivateRoute component={PersonaPage} />} />
    </Routes>
  );
};
